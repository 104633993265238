.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContent {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 400px;
  position: relative;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.closeButtonModal {
  position: absolute;
  background-color: black;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

h2 {
  margin-bottom: 20px;
  text-align: center;
}

.inputBox {
  width: calc(100% - 30px);
  padding: 10px 15px;
  margin-bottom: 15px;
  font-size: 1rem;
  border-radius: 5px;
  border: 2px solid #007bff;
  outline: none;
  transition: all 0.3s ease;
}

.inputBox:focus {
  border-color: #0056b3;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
}

.createButtonModal {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.createButton:hover {
  background-color: #0056b3;
}

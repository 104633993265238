.container {
  background-color: #1a202c;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  margin: 20px auto;
  color: #fff;
  font-family: Arial, sans-serif;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #e2e8f0;
}

.description {
  font-size: 14px;
  margin-bottom: 20px;
  color: #a0aec0;
}

.serviceList {
  margin-top: 10px;
}

.serviceItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 8px;
  background-color: #2d3748;
  transition: transform 0.2s ease-in-out;
}

.serviceItem:hover {
  transform: translateY(-3px);
}

.statusDot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 15px;
}

.active {
  background-color: #28a745;
}

.inactive {
  background-color: #dc3545;
}

.serviceName {
  flex: 1;
  font-size: 16px;
  font-weight: 500;
}

.status {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@media (max-width: 480px) {
  .container {
    padding: 15px;
  }

  .title {
    font-size: 20px;
  }

  .serviceName {
    font-size: 14px;
  }

  .statusDot {
    width: 10px;
    height: 10px;
    margin-right: 8px;
  }

  .status {
    font-size: 12px;
  }
}

.nav-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px 40px 0px 40px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  background-color: rgba(237, 237, 237, 0);
  z-index: 1100;
}

.nav-bar div {
  display: flex;
}
.nav-bar div .logout {
  display: flex;
  flex-direction: row;
  margin: 5px;
  align-items: center;
  padding: 6px 14px;
  font-family: Poppins, sans-serif;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  z-index: 1000;

  background: grey;
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.1),
    inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.5),
    0px 0px 0px 0.5px rgba(0, 0, 0, 0.12);
  color: #dfdedf;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.nav-bar div .services {
  display: flex;
  flex-direction: row;
  margin: 5px;

  align-items: center;
  padding: 6px 14px;
  font-family: Poppins, sans-serif;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  z-index: 1000;

  background: darkcyan;
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.1),
    inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.5),
    0px 0px 0px 0.5px rgba(0, 0, 0, 0.12);
  color: #dfdedf;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-12:focus {
  box-shadow: inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2),
    0px 0.5px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
  outline: 0;
}

.nav-bar .homeButton {
  font-family: Poppins, sans-serif;
  display: flex;
  flex-direction: row;
  margin: 5px;

  align-items: center;
  padding: 6px 14px;
  border-radius: 6px;
  border: none;
  position: sticky;
  top: 0;
  z-index: 1000;
  left: 20px;
  cursor: pointer;

  color: #fff;
  background: linear-gradient(180deg, #4b91f7 0%, #367af6 100%);
  background-origin: border-box;
  box-shadow: 0px 0.5px 1.5px rgba(54, 122, 246, 0.25),
    inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.nav-bar .homeButton:focus {
  box-shadow: inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2),
    0px 0.5px 1.5px rgba(54, 122, 246, 0.25),
    0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
  outline: 0;
}

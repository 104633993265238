/* .container {
  background-color: rgb(187, 202, 207);
  padding: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  overflow-y: auto;
  margin: 35px auto;
  height: fit-content;
}

.container h1 {
  font-size: 28px;
  margin-bottom: 10px;
  color: #333;
}

.container p {
  font-size: 16px;
  margin-bottom: 10px;
  color: #666;
}

.input-group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 0;
}

.input-group .prefix,
.input-group .suffix {
  background-color: #e0e5ea;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 100px;
  text-align: center;
}

.input-group input {
  width: calc(100% - 220px);
  padding: 8px;
  margin: 0;
  border: 1px solid #ccc;
  border-left: none;
  border-right: none;
  font-size: 16px;
  border-radius: 0;
}

.container button {
  display: flex;
  flex-direction: row;
  padding: 6px 14px;
  font-family: Poppins, sans-serif;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  margin: 20px 0 0 auto;
  background: #6e6d70;
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.1),
    inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.5),
    0px 0px 0px 0.5px rgba(0, 0, 0, 0.12);
  color: #dfdedf;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

button:focus {
  box-shadow: inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2),
    0px 0.5px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
  outline: 0;
}

.container button:hover {
  background-color: #0056b3;
}

.input-group-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}

.input-group-dropdown {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.input-group-time {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.input-group-dropdown select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: auto;
}

.question {
  margin-top: 10px;
  margin-bottom: 10px !important;
  color: #333 !important;
  font-size: 14px !important;
  font-weight: bold;
}

.select-class {
  width: 200px;
}

.input-group-time input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: auto;
  height: 18px;
}

.input-group-maxquestions {
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.input-group-maxquestions input {
  width: 120px;
  padding: 8px;
  margin: 0;
  border: 1px solid #ccc;
  border-left: none;
  border-right: none;
  font-size: 16px;
  border-radius: 0;
  text-align: center;
}

.toggle-container {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #465a6b;
}

input:checked + .slider:before {
  transform: translateX(17px);
}

.toggle-h1-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.toggle-h1-container h1 {
  margin: 0;
}

.toggle-container span {
  margin-left: 10px;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin: 0px;
  width: 190px;
  margin: auto;
}

.checkbox-container input {
  width: 15px;
  height: 15px;
}

.checkbox-container input[type="checkbox"] {
  accent-color: #465a6b;
}

.checkbox-container label {
  font-size: 14px;
  font-weight: bold;
  color: rgb(35, 35, 35);
}

.input-group-name input {
  padding: 8px;
  margin: 0;
  border: 1px solid #ccc;
  border-left: none;
  border-right: none;
  font-size: 16px;
  border-radius: 0;
  text-align: center;
  border-radius: 5px;
} */
/* 
body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.container {
  background-color: rgb(187, 202, 207);
  padding: 25px 25px 25px 35px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 100%;
  max-width: calc(100% - 30px);
  overflow-y: auto;
  margin: 35px 15px;
  height: fit-content;
}

.container h1 {
  font-size: 28px;
  margin-bottom: 10px;
  color: #333;
}

.container p {
  font-size: 16px;
  margin-bottom: 10px;
  color: #666;
}

.input-group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 0;
}

.input-group .prefix,
.input-group .suffix {
  background-color: #e0e5ea;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 100px;
  text-align: center;
}

.input-group input {
  width: calc(100% - 220px);
  padding: 8px;
  margin: 0;
  border: 1px solid #ccc;
  border-left: none;
  border-right: none;
  font-size: 16px;
  border-radius: 0;
}

.container button {
  display: block;
  padding: 6px 14px;
  font-family: Poppins, sans-serif;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  margin: 20px auto;
  background: #6e6d70;
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.1),
    inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.5),
    0px 0px 0px 0.5px rgba(0, 0, 0, 0.12);
  color: #dfdedf;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 80%;
}

button:focus {
  box-shadow: inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2),
    0px 0.5px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
  outline: 0;
}

.container button:hover {
  background-color: #0056b3;
}

.input-group-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}

.input-group-dropdown {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.input-group-time {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.input-group-dropdown select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: auto;
}

.question {
  margin-top: 10px;
  margin-bottom: 10px !important;
  color: #333 !important;
  font-size: 14px !important;
  font-weight: bold;
}

.select-class {
  width: 200px;
}

.input-group-time input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: auto;
  height: 18px;
}

.input-group-maxquestions {
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.input-group-maxquestions input {
  width: 120px;
  padding: 8px;
  margin: 0;
  border: 1px solid #ccc;
  border-left: none;
  border-right: none;
  font-size: 16px;
  border-radius: 0;
  text-align: center;
}

.toggle-container {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #465a6b;
}

input:checked + .slider:before {
  transform: translateX(17px);
}

.toggle-h1-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.toggle-h1-container h1 {
  margin: 0;
}

.toggle-container span {
  margin-left: 10px;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin: 0px;
  width: 190px;
  margin: auto;
}

.checkbox-container input {
  width: 15px;
  height: 15px;
}

.checkbox-container input[type="checkbox"] {
  accent-color: #465a6b;
}

.checkbox-container label {
  font-size: 14px;
  font-weight: bold;
  color: rgb(35, 35, 35);
}

.input-group-name input {
  padding: 8px;
  margin: 0;
  border: 1px solid #ccc;
  border-left: none;
  border-right: none;
  font-size: 16px;
  border-radius: 0;
  text-align: center;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .container {
    padding: 20px;
    margin: 20px 10px;
  }

  .input-group input {
    width: calc(100% - 180px);
  }

  .input-group .prefix,
  .input-group .suffix {
    width: 80px;
  }

  .input-group-row {
    flex-direction: column;
    align-items: stretch;
  }

  .input-group-maxquestions,
  .input-group-time {
    width: 100%;
    margin-bottom: 15px;
  }

  .input-group-maxquestions input,
  .input-group-time input {
    width: 100%;
  }

  .select-class {
    width: 100%;
  }

  .toggle-h1-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .input-group-name input {
    width: 100%;
    margin-bottom: 10px;
  }

  .toggle-container {
    align-self: flex-end;
  }
} */

body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.container {
  background-color: rgb(187, 202, 207);
  padding: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 100%;
  max-width: calc(100% - 30px);
  overflow-y: auto;
  margin: 35px auto;
  height: fit-content;
}

.container h1 {
  font-size: 28px;
  margin-bottom: 10px;
  color: #333;
}

.container p {
  font-size: 16px;
  margin-bottom: 10px;
  color: #666;
}

.input-group {
  display: flex;
  align-items: center;
  margin: 12px 0;
}

.input-group .prefix,
.input-group .suffix {
  background-color: #e0e5ea;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 100px;
  text-align: center;
}

.input-group input {
  width: calc(100% - 220px);
  padding: 8px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.container button {
  display: block;
  padding: 6px 14px;
  font-family: Poppins, sans-serif;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  margin: 20px auto;
  background: #6e6d70;
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.1),
    inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.5),
    0px 0px 0px 0.5px rgba(0, 0, 0, 0.12);
  color: #dfdedf;
  width: 80%;
}

button:focus {
  box-shadow: inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2),
    0px 0.5px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
  outline: 0;
}

.container button:hover {
  background-color: #0056b3;
}

.input-group-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}

.input-group-time,
.input-group-maxquestions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.input-group-time input,
.input-group-maxquestions input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: auto;
}

.input-group-dropdown {
  width: auto;
  margin-bottom: 10px;
}

.question {
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #333;
  font-size: 14px;
  font-weight: bold;
  width: fit-content;
}

.select-class {
  width: 160px;
}

.input-group-name input {
  padding: 8px;
  border: 1px solid #ccc;
  font-size: 16px;
  border-radius: 5px;
  text-align: center;
}

.checkbox-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: auto;
}

.checkbox-container div {
  display: flex;
  align-items: center;
}

.checkbox-container input {
  width: 15px;
  height: 15px;
}

.checkbox-container input[type="checkbox"] {
  accent-color: #465a6b;
}

.checkbox-container label {
  font-size: 14px;
  font-weight: bold;
  color: rgb(35, 35, 35);
}

.toggle-container {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.dropdown-combine {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}
.dropdown-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #465a6b;
}

input:checked + .slider:before {
  transform: translateX(17px);
}

.toggle-h1-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.toggle-h1-container h1 {
  margin: 0;
}

.toggle-container span {
  margin-left: 10px;
}

@media (max-width: 768px) {
  .container {
    padding: 20px;
    margin: auto;
  }

  .input-group input {
    width: calc(100% - 180px);
  }

  .input-group .prefix,
  .input-group .suffix {
    width: 80px;
  }

  .input-group-row {
    flex-direction: column;
    align-items: stretch;
  }

  input:checked + .slider {
    background-color: #465a6b;
  }

  input:checked + .slider:before {
    transform: translateX(13px);
  }

  .input-group-maxquestions,
  .input-group-time {
    width: 100%;
    margin-bottom: 15px;
  }

  .input-group-maxquestions input,
  .input-group-time input {
    width: 100%;
  }

  .select-class {
    width: 100%;
  }

  .toggle-h1-container {
    margin-top: auto;
    flex-direction: row;
    align-items: flex-start;
  }

  .input-group-name input {
    width: 100%;
    margin-bottom: 10px;
  }
  .dropdown-div {
    display: flex;
    flex-direction: column;
  }
  .checkbox-container {
    display: flex;
    /* justify-content: space-between; */
    flex-direction: column;
    margin: auto;
  }
  .checkbox-container div {
    margin-bottom: 10px;
  }
}

@media (max-width: 400px) and (max-height: 800px) {
  .toggle-h1-container {
    margin-top: 140px;
    flex-direction: row;
    align-items: flex-start;
  }
}

body {
  font-family: "Arial", sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url("./assets/background1.jpg");
  background-color: #d9e4f5;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 600px;
  padding: 20px;
  box-sizing: border-box;
}

.login-container {
  display: flex;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 1000px;
  height: 80%;
  flex-direction: row;
}

.login-image img {
  width: 100%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 100%;
  object-fit: cover;
}

.login-form {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 40px;
  width: 40%;
  max-width: 500px;
  box-sizing: border-box;
}

.login-form p {
  color: #666;
}

.login-form h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.login-form form {
  display: flex;
  flex-direction: column;
}

.login-form input[type="email"],
.login-form input[type="password"] {
  background-color: rgb(237, 237, 237);
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.login-form input[type="email"]:focus,
.login-form input[type="password"]:focus {
  border-color: gray;
  outline: none;
}

.login-form button {
  align-self: center;
  width: 100%;
  margin-top: 20px;
  padding: 12px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background: #5573bd;
  color: white;
  cursor: pointer;
  transition: 0.3s ease;
}

.login-form button:hover {
  background: #4c51bf;
}

.nav-signup-container {
  color: #666;
  text-align: center;
  font-size: 14px;
  margin-top: 20px;
}

.nav-signup {
  color: #5a67d8;
  text-decoration: none;
  transition: color 0.3s;
}

.nav-signup:hover {
  color: #4c51bf;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .login-container {
    flex-direction: column;
    height: auto;
    max-width: 90%;
  }

  .login-image img {
    border-radius: 10px 10px 0 0;
    height: 200px;
  }

  .login-form {
    border-radius: 0 0 10px 10px;
    width: 100%;
    padding: 20px;
  }

  .login-form input[type="email"],
  .login-form input[type="password"] {
    font-size: 14px;
  }

  .login-form button {
    padding: 10px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .login-form h2 {
    font-size: 20px;
  }

  .login-form p {
    font-size: 14px;
  }

  .login-form input[type="email"],
  .login-form input[type="password"] {
    font-size: 14px;
  }

  .login-form button {
    font-size: 14px;
    padding: 10px;
  }
}

.botListContainer {
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.createBotButton {
  font-family: Poppins, sans-serif;
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
  padding: 6px 14px;
  border-radius: 6px;
  border: none;
  position: sticky;
  top: 0;
  z-index: 1200;
  left: 20px;
  cursor: pointer;

  color: #fff;
  background: linear-gradient(180deg, #4b91f7 0%, #367af6 100%);
  background-origin: border-box;
  box-shadow: 0px 0.5px 1.5px rgba(54, 122, 246, 0.25),
    inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.createBotButton:focus {
  box-shadow: inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2),
    0px 0.5px 1.5px rgba(54, 122, 246, 0.25),
    0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
  outline: 0;
}

.createBotButton:hover {
  background-color: #0056b3;
}

.fullPageGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 40px;
}

.botItem {
  position: relative;
  background-color: whitesmoke;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s;
}

.botItemActions {
  position: absolute;
  top: 10px;
  right: 10px;
}

.botItemActions button {
  cursor: pointer;
}

.botItem:hover {
  transform: scale(1.03);
}

.botTitle {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.botTitle span {
  color: black;
  font-weight: 600;
  font-size: 16px;
  padding: 10px;
}

.botInfo {
  font-size: 14px;
  margin: 8px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding-bottom: 3px;
}

.botInfo span {
  color: black;
  font-weight: 500;
  font-size: 16px;
}

.noBots {
  grid-column: 1 / -1;
  text-align: center;
  font-size: 20px;
  color: #666;
  padding: 40px;
  background-color: #9daeb8;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .fullPageGrid {
    grid-template-columns: 1fr;
  }

  .botItem {
    padding: 15px;
  }

  .botTitle {
    font-size: 20px;
  }

  .botInfo {
    font-size: 15px;
  }
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.statusCircle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}

.statusCircle.green {
  background-color: rgb(85, 169, 85);
}

.statusCircle.red {
  background-color: rgb(198, 87, 87);
}

.statusCircle.yellow {
  background-color: gold;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

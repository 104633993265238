.deleteButton {
  padding: 10px;
  background-color: rgb(230, 65, 65);
  border: 2px solid rgb(230, 65, 65);
  border-radius: 5px;
  color: white;
  cursor: pointer;
  margin: 10px;
}

.createButton {
  padding: 10px;
  background-color: green;
  border: 2px solid green;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  margin: 10px;
}

.updateButton {
  padding: 10px;
  background-color: #228be6;
  border: 2px solid #228be6;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  margin: 10px;
}

.container {
  background-color: #1a202c;
  padding: 20px;
  border-radius: 10px;
  width: 500px;
  height: 222px;
  margin: auto;
  color: #fff;
  font-family: Arial, sans-serif;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 100;
}

.mainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 992px) {
  .mainContainer {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .container {
    width: 300px;
  }
}
